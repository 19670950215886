/**
 * PaypalSuccess
 * Redirect target to capture successful transaction data
 *
 */

import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PaypalSuccess = () => {
  const query = useQuery();

  const referrer = window.document.referrer;
  const paramsString = window.location.search;
  const searchParams = new URLSearchParams(paramsString);
  const params = Array.from(searchParams.entries()).reduce(
    (acc, [k, v]) => ({ ...acc, [k]: v }),
    {}
  );
  return (
    <>
      <h1>PaypalSuccess</h1>
      <h2>Referrer= {referrer}</h2>
      <div>paramsString= {paramsString}</div>
    </>
  );
};

export default PaypalSuccess;

/*
Typical redirect:
https://dev.oksayit.com/paypal-success?PayerID=5SU2R7VZELBRS&st=Completed&tx=7JH47155Y8987682F&cc=USD&amt=1.00&payer_email=jsulmar%40alum.mit.edu&payer_id=5SU2R7VZELBRS&payer_status=VERIFIED&first_name=Joseph&last_name=Sulmar&address_name=Joseph%20Sulmar&address_street=3%20Viles%20Road&address_city=Lexington&address_state=MA&address_country_code=US&address_zip=02421&residence_country=US&txn_id=7JH47155Y8987682F&mc_currency=USD&mc_fee=0.52&mc_gross=1.00&protection_eligibility=ELIGIBLE&payment_fee=0.52&payment_gross=1.00&payment_status=Completed&payment_type=instant&handling_amount=0.00&shipping=0.00&item_name=OkSayit%20Super%20One%20Month&item_number=2200&quantity=1&txn_type=web_accept&payment_date=2024-01-13T18%3A21%3A49Z&receiver_id=75HV2HVJAFT3W&notify_version=UNVERSIONED&verify_sign=AjnbNDIilnxl9uKzJ0TGir2cRWUtAuG8UkEk.mta.Qfq--w-0W4p.jTB

PayerID=5SU2R7VZELBRS
&st=Completed
&tx=7JH47155Y8987682F
&cc=USD
&amt=1.00
&payer_email=jsulmar%40alum.mit.edu
&payer_id=5SU2R7VZELBRS
&payer_status=VERIFIED
&first_name=Joseph
&last_name=Sulmar
&address_name=Joseph%20Sulmar
&address_street=3%20Viles%20Road
&address_city=Lexington&address_state=MA
&address_country_code=US
&address_zip=02421
&residence_country=US
&txn_id=7JH47155Y8987682F
&mc_currency=USD&mc_fee=0.52
&mc_gross=1.00
&protection_eligibility=ELIGIBLE
&payment_fee=0.52
&payment_gross=1.00
&payment_status=Completed
&payment_type=instant
&handling_amount=0.00
&shipping=0.00
&item_name=OkSayit%20Super%20One%20Month
&item_number=2200
&quantity=1
&txn_type=web_accept
&payment_date=2024-01-13T18%3A21%3A49Z
&receiver_id=75HV2HVJAFT3W
&notify_version=UNVERSIONED
&verify_sign=AjnbNDIilnxl9uKzJ0TGir2cRWUtAuG8UkEk.mta.Qfq--w-0W4p.jTB
*/
