/**
 * TEMPORARY: bypassValidation for select list of ANIs
 *
 * SMSAuthenticator
 * OTP (One-Time Password) SMS implementation with Twilio
 *
 */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { phone as phoneAttributes } from 'phone';
import TextFieldStd from 'components/utils/TextFieldStd';
import SelectorStd from 'components/utils/SelectorStd';
import ButtonStd from 'components/utils/ButtonStd';
import { uiActions } from 'store/ui-slice';
import ExceptionDialog from 'components/Modals/ExceptionDialog';
import { fetcher } from 'lib/utils/fetcher';
import { twilioFrom, twilioFromFriendly } from 'configs/config-hvn';
import { currentCommunity } from 'lib/api/communityApi';
import { t } from 'lib/translation/trans';

const bypassValidation = e164 => {
  console.log(`bypassValidation e164=${e164}`);
  const testerList = [
    //'+19739060001', // chas
    //'+16174294899', // joe
    //'+15105528284', //alvin
  ];
  return testerList.includes(e164);
};

const useStyles = makeStyles(theme => ({
  // override styles for SelectorStd
  formControl: {
    margin: 0,
    width: 70,
    marginBottom: '1rem',
  },
}));

/*
// example twilio SMS send
// NOTE: twilio client does not work from web-browser, only from Node.js or server side
// Therefore a POST to REST API is used here
// Also note that the API requires form data, not ('Content-Type', 'application/json')
// TODO2: employ a more secure means, perhaps server side Twilio relay

var url= 'https://api.twilio.com/2010-04-01/Accounts/ACe6a594f973087eb6637f1be041dc92ba/Messages.json'
var formData = new FormData()
formData.append('To', '+16174294899')
formData.append('From', '+17654444446')
formData.append('Body', 'formData Test #3')
var headers = new Headers()
headers.append('Accept', 'application/json');
//headers.append('Content-Type', 'application/json');
headers.append( 'authorization', 'Basic QUNlNmE1OTRmOTczMDg3ZWI2NjM3ZjFiZTA0MWRjOTJiYTo2NmVkNTM5NzE5ODRkNDI5ZjgxZjE1MWVhODc5N2Q2ZA==')

var options = {
  method: 'POST',
  body: formData,
  headers: headers
}

fetch(url, options )
.then(response => response.json() )
.then(data => console.log(`sid=${data.sid}, status=${data.status}, error_code=${data.error_code}, error_message=${data.error_message}`))
.catch(err => console.log("err:", err))



*/

/*
  curl implementation (works from MAC):
  curl -L -X POST 'https://api.twilio.com/2010-04-01/Accounts/ACe6a594f973087eb6637f1be041dc92ba/Messages' -H 'Authorization: Basic QUNlNmE1OTRmOTczMDg3ZWI2NjM3ZjFiZTA0MWRjOTJiYTo2NmVkNTM5NzE5ODRkNDI5ZjgxZjE1MWVhODc5N2Q2ZA==' -F 'To="+16174294899"' -F 'From="+17654444446"' -F 'Body="Test to Joe with headers"'
*/

/*
  Also consider: https://www.twilio.com/blog/serverless-sms-messaging-javascript-twilio-functions
  Also consider messaging service: https://www.twilio.com/docs/messaging/services

*/

const SMSAuthenticator = ({
  setHeading,
  onSuccess,
  onFail,
  onClose,
  enableCodeSuccessAlert,
}) => {
  const [phone, setPhone] = useState(''); //phone entered on form
  const [e164, setE164] = useState(''); //normalized phone in E.164 format
  const [phoneFieldEn, setPhoneFieldEn] = useState(true); // enable phone field and button
  const [valCode, setValCode] = useState(''); //validation code entered on form
  const [valCodeSecret, setValCodeSecret] = useState(''); // secret validation code to match up
  const [valCodeFieldEn, setValCodeFieldEn] = useState(false); //enable valCode field and button
  const [exception, setException] = useState(null); //exception description object

  // obtain a list of community-specific destination countries for SMS
  const [country, setCountry] = useState('US');

  // the current community might specify a list of service countries, oterwise undefined
  //const countries = currentCommunity()?.params?.membershipCountries;
  const countries = ['US', 'LT', 'FR'];

  const countryFieldEn = countries && phoneFieldEn;

  const classes = useStyles();
  const dispatch = useDispatch();
  const phoneFieldRef = useRef();

  // initialize
  useEffect(() => {
    setHeading && setHeading(t('Logging_in_with_mobile_text'));

    // be sure to clear focus flag on dismount
    return () => {
      dispatch(uiActions.noteFieldFocus(false));
    };
  }, []);

  // send SMS message
  useEffect(() => {
    if (e164) {
      //const msg = `${t('Your_okSayit_account_verification_code_is')}: ${createCode()}`;

      const msg =
        `Your OkSayit account verification code is ${createCode()}. ` +
        '\n\n' +
        `Call ${twilioFromFriendly} to report if this request was not made by you.`;

      if (bypassValidation(e164)) {
        // for development only
        setValCodeSecret('12345');
        setValCode('12345');
        handleCodeSubmit();
      } else {
        send({ to: e164, msg });
      }
    }
  }, [e164]);

  // --- Helpers ------------------------------------------------------------------------------

  // send SMS text message
  const send = ({ to, msg }) => {
    var formData = new FormData();
    formData.append('To', to);
    formData.append('From', twilioFrom);
    formData.append('Body', msg);
    console.log(`SMS: To ${to}: ${msg}`); //TODO2: remove this
    fetcher('sms', 'POST', '', formData)
      .then(rslt => {
        console.log('SMS result:', rslt);
      })
      .catch(err => {
        console.log('SMS Error:', err);
      });
  };

  // get a random integer
  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
  };

  // create a random secret valCode
  const createCode = () => {
    const code = getRandomInt(10000, 99999).toString();
    setValCodeSecret(code);
    return code;
  };

  // for each supported country, specify config parameters
  const countryConfig = {
    default: {
      name: '',
      countryCode: '',
      placeholder: '',
      warnings: [
        t('is_not_a_valid_phone_number'),
        t('Please_enter_a_valid_phone_number'),
      ],
      humanReadable: () => null,
    },
    US: {
      name: 'United States',
      countryCode: '',
      placeholder: '(xxx) xxx-xxxxx',
      warnings: [
        t('is_not_a_US_or_Canada_phone_number'),
        t('Please_enter_a_US_or_Canada_phone_number'),
      ],
      // convert e164 phone format from +1xxxxxxxxxx to (xxx) xxx-xxxx
      humanReadable: e_164 =>
        `(${e_164.slice(2, 5)}) ${e_164.slice(5, 8)}-${e_164.slice(8, 12)}`,
    },
    CA: {
      name: 'Canada',
      countryCode: '',
      placeholder: '(xxx) xxx-xxxxx',
      warnings: [
        t('is_not_a_US_or_Canada_phone_number'),
        t('Please_enter_a_US_or_Canada_phone_number'),
      ],
      // convert e164 phone format from +1xxxxxxxxxx to (xxx) xxx-xxxx
      humanReadable: e_164 =>
        `(${e_164.slice(2, 5)}) ${e_164.slice(5, 8)}-${e_164.slice(8, 12)}`,
    },
    FR: {
      name: 'France',
      countryCode: '33',
      placeholder: 'x xx xx xx xx',
      warnings: [
        t('is_not_a_mobile_phone_in_France'),
        t('Please_enter_a_mobile_number_in_France'),
      ],
      // convert e164 phone format from +336xxxxxxxx to 6 xx xx xx xx
      humanReadable: e_164 =>
        `${e_164.slice(3, 4)} ${e_164.slice(4, 6)} ${e_164.slice(
          6,
          8
        )} ${e_164.slice(8, 10)} ${e_164.slice(10)}`,
    },
    LT: {
      name: 'Lithuania',
      countryCode: '370',
      placeholder: '6xxxxxxx',
      warnings: [
        t('is_not_a_mobile_phone_in_Lithuania'),
        t('Please_enter_a_mobile_number_in_Lithuania'),
      ],
      // convert e164 phone format from +3706xxxxxxx
      humanReadable: e_164 => e_164,
    },
  };

  // placeholder for phone field depends on the country selected
  // by default, US and CA numbers are accepted
  const getPhonePlaceholder = () => {
    const config = countries && country && countryConfig[country];
    return config ? config.placeholder : countryConfig.US.placeholder;
  };

  // --- Result Actions ------------------------------------------------------------------------------

  const handleSuccess = () => {
    onSuccess &&
      onSuccess({
        provider: 'SMS',
        socId: e164.substr(2), // strip off '+1'
        e164,
        phone,
      });
  };

  const handleFail = () => {
    //TODO2 is handleFail needed by SMSAuthenticator?
  };

  // --- Validation ------------------------------------------------------------------------------

  const phoneValidationError = () => {
    // if no country list is provided, enforce US/CA phone number
    // othewise validate for the selected country
    const enforceCountry = countries ? country : 'US';

    //default error object
    const errObj = {
      heading: `"${phone}" ${countryConfig[enforceCountry].warnings[0]}`,
      body: countryConfig[enforceCountry].warnings[1],
      actions: null,
      onClose: () => {
        setException(null);
        setPhone('');
      },
    };

    // only employ optional arg if country is specified by the Community
    const { isValid, phoneNumber, countryIso2 } =
      countries && country && country !== 'US'
        ? phoneAttributes(phone, { country })
        : phoneAttributes(phone);

    console.log(`E.164: ${phoneNumber}, country: ${countryIso2}`);

    // accept a match for enforceCountry,
    // also accept CA if enforceCountry is US
    const goodCountry =
      countryIso2 === enforceCountry ||
      (countryIso2 === 'CA' && enforceCountry === 'US');
    //TODO2: add better phone number country check
    // e.g. '441' (Bermuda) is reported as US

    if (isValid && goodCountry) {
      setE164(phoneNumber); // save standardized format-- NOTE this triggers send SMS
      setPhone(countryConfig[enforceCountry].humanReadable(phoneNumber)); //clean up format of the phone field
      return null; // all OK
    }
    return errObj;
  };

  const valCodeValidationError = () => {
    // default error object
    const errObj = {
      heading: t('Incorrect_Validation_Code'),
      body: t('Please_check_the_code_we_sent'),
      actions: null,
      onClose: () => {
        setException(null);
        setValCode('');
      },
    };

    const valCodeError = () => valCode !== valCodeSecret;

    if (valCodeError()) {
      //verify user is authenticated
      errObj.heading = t('Incorrect_Validation_Code');
      errObj.body = t('Please_check_the_code_we_sent');
    } else {
      return null; // all OK
    }
    return errObj;
  };

  // --- Event Handlers ------------------------------------------------------------------------------

  const handlePhoneSubmit = () => {
    const errObj = phoneValidationError();

    if (!Boolean(errObj)) {
      // explain that a validation code is being sent.
      setValCodeFieldEn(true);
      setPhoneFieldEn(false);
      dispatch(
        uiActions.alertSet({
          message: `${t('We_are_texting_a_code_to')} ${phone} ${t('now')}. ${t(
            'It_should_only_take_a_few_seconds'
          )}`,
          severity: 'info',
        })
      );
    } else {
      // failed validation, generate a notice
      console.log(`validation exception: errObj`);
      setException(errObj);
    }
  };

  // verify that user entered good code
  const handleCodeSubmit = () => {
    const errObj = valCodeValidationError();
    if (!Boolean(errObj)) {
      enableCodeSuccessAlert &&
        dispatch(
          uiActions.alertSet({
            message: t('Great_your_phone_is_verified'),
            severity: 'success',
          })
        );
      handleSuccess();
    } else {
      // failed validation, generate a notice
      console.log(`validation exception: errObj`);
      setException(errObj);
    }
  };

  const handleCountryChange = c => {
    if (c !== country) {
      setCountry(c);
    }
  };

  // --- Rendering ------------------------------------------------------------------------------

  const countryField = () => (
    <SelectorStd
      name="country"
      label={t('Country')}
      items={countries.map(item => ({ key: item, data: item }))}
      value={country}
      onChange={handleCountryChange}
      classes={classes}
    />
  );

  // handle phone change
  //@@ consider adding this feature to TextFieldStd
  // detect auto-fill and re-render TextFieldStd
  // (mitigates disruptive forced background on Safari)
  // ref.:  https://stackoverflow.com/questions/41871618/how-to-change-safari-autofill-yellow-background-color
  // ref.:   https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete/32505530#32505530
  const [textFieldKey, setTextFieldKey] = useState(0);
  const handlePhoneChange = ev => {
    const val = ev.target.value;
    const jumpCnt = val.length - phone.length;
    setPhone(val);
    if (jumpCnt > 2) {
      // pause, then re-render TextFieldStd
      setTimeout(() => {
        setTextFieldKey(k => ++k);
      }, 200);
    }
  };
  const annotationWidth = phoneFieldRef.current?.offsetWidth || 306;
  const phoneField = () => (
    <>
      <div ref={phoneFieldRef}>
        <TextFieldStd
          key={textFieldKey}
          label={t('Phone')}
          value={phone}
          placeholder={getPhonePlaceholder()}
          helperText={t('Enter_your_MOBILE_number')}
          onChange={handlePhoneChange}
          disabled={valCodeFieldEn}
          onEnter={handlePhoneSubmit}
          inputRef={
            input =>
              !countryFieldEn &&
              input &&
              input.focus() /* WARNING: only one rendered field at a time can do this   */
          }
        />
      </div>
      <p className={`MuiFormHelperText-root MuiFormHelperText-contained`}>
        {t('Mobile_phones_only')}
      </p>

      <Grid
        style={{
          width: { annotationWidth },
          marginTop: '2rem',
          padding: '.5rem 1rem',
        }}
        container
        alignItems="center"
      >
        <Grid item xs={3}>
          <ButtonStd
            onClick={handlePhoneSubmit}
            label={t('Next')}
            color="secondary"
            disabled={valCodeFieldEn}
          />
        </Grid>
        <Grid item xs={9}>
          <Typography variant="caption">
            {t('By_pressing_next_you_agree')}
          </Typography>
        </Grid>
      </Grid>
    </>
  );

  const validationField = () => (
    <>
      <TextFieldStd
        label={t('Enter_Your_Code')}
        value={valCode}
        placeholder={null}
        helperText={`${t('Enter_the_code_we_sent_to')} ${phone}`}
        onChange={ev => setValCode(ev.target.value)}
        onEnter={handleCodeSubmit}
        inputRef={
          input =>
            input &&
            input.focus() /* WARNING: only one rendered field at a time can do this   */
        }
      />
      <div style={{ height: '.5rem' }}></div>
      <ButtonStd onClick={handleCodeSubmit} label={t('Validate')} />
    </>
  );

  return (
    <>
      {countryFieldEn && countryField()}
      {phoneFieldEn && phoneField()}
      {valCodeFieldEn && validationField()}
      <ExceptionDialog
        open={Boolean(exception)}
        onClose={exception && exception.onClose}
        heading={exception && exception.heading}
        body={exception && exception.body}
        actions={exception && exception.actions}
      />
    </>
  );
};

SMSAuthenticator.propTypes = {
  enableCodeSuccessAlert: PropTypes.bool,
};
SMSAuthenticator.defaultProps = {
  enableCodeSuccessAlert: true,
};

export default SMSAuthenticator;
