import { keys } from '@material-ui/core/styles/createBreakpoints';
import { createSlice } from '@reduxjs/toolkit';
import {
  isTopic,
  isResponse,
  getFamilyId,
  normalizeDialogs,
  directedThreadKey,
  organizeThread,
  isThreadedTopic,
  isDirectedType,
} from 'lib/api/messageApi';
import { dialogsExcludeBlacklist } from 'lib/utils/filters';
import { makeArray } from 'lib/utils/utils';
import { activityDetector } from 'lib/classes/ActivityDetector';
import { updateBadge } from 'lib/api/webPushApi';

//TODO2: * eliminate non-stringifiable store content

// A dialog consists of a 'topic' message and zero or more child 'response' messages.
// Dialogs are threaded only 1 layer deep, i.e. there are no responses to responses.
// Each message is either designated as 'public' or 'directed' to a specific user.
// Dialogs are stored with nested responses in the dialogs array, and also
// flattened in the messageSequence array (topics and responses stored individually)
const initialState = {
  // UI controls
  visibility: 'public', // 'public' | 'directed'
  browserAuto: false, // automatically advance while browsing
  browserFilterPostsOnly: true, // bypass responses with auto or manually advancing
  dialogsFetchedFlg: false, //indicates if dialogs have been fetched
  dialogs: [], // array of posts with embedded array of responses (threaded)
  inboundIdQueue: [], // unthreaded list of inbound directed messages
  inboundIdQueueInitialized: false, //asserted when valid data in inboundIdQueue

  played: [], // list of messages already played this session
  // used by Playlist_v1 to determine annotation of messages that have completed play

  selectedMessageId: null, // id of currently selected post or response
  preloadSelectedMessageId: null, // a suggested id to be selected once dialogs are fetched
  familyId: null, // the id of a message's parent, or it's own id if a Topic message
  openTopicId: null, // id of the currently open post (responses expanded in UI)

  /**
   * normalized (flattened) representation of topics
   * ordered list of message id's (topics and responses)
   * Each topic is immediately followed by its child responses.
   */
  messageSequence: [],
  messageMap: {}, // maps message id's to message objects

  // public and directed dialogs context variables are buffered separately
  // this enables the UI to switch back and forth between public/directed without losing respective context
  contextBuffer: {
    public: {
      selectedMessageId: null, // id of currently selected topic or response
      familyId: null, // the id of a message's parent, or it's own id if a Topic message
      openTopicId: null, // id of the currently open topic (responses expanded in UI)
    },
    directed: {
      selectedMessageId: null,
      familyId: null,
      openTopicId: null,
    },
  },
};

// new dialogs installation utility
// derives messageSequence, messageMap from newDialogs
const install = (state, newDialogs) => {
  if (newDialogs) {
    state.dialogs = newDialogs;
    const { messageSequence, messageMap } = normalizeDialogs(newDialogs);
    state.messageSequence = messageSequence;
    state.messageMap = messageMap;
  }
};

// enqueue an inbound directed message
const enqueue = (state, message, userId) => {
  if (isDirectedType(message) && message.recipient === userId) {
    state.inboundIdQueue = [message.id, ...state.inboundIdQueue];
    updateBadge(state.inboundIdQueue.length).catch(msg => console.log(msg));
  }
};

// dequeue a list of messages that might be in the queue
const dequeue = (state, messageIdArr) => {
  console.log('dequeue list:', messageIdArr);
  const newQ = state.inboundIdQueue.filter(
    id => !makeArray(messageIdArr).includes(id)
  );
  state.inboundIdQueue = newQ;
  updateBadge(newQ.length).catch(msg => console.log(msg));
};

const dialogSlice = createSlice({
  name: 'dialogs',
  initialState: initialState,
  reducers: {
    // set public vs directed message visibility filter
    setVisibilityFilter(state, action) {
      const newVisibility = action.payload;
      if (newVisibility !== state.visibility) {
        // buffer the current context before switching
        state.contextBuffer[state.visibility].selectedMessageId =
          state.selectedMessageId;
        state.contextBuffer[state.visibility].familyId = state.familyId;
        state.contextBuffer[state.visibility].openTopicId = state.openTopicId;

        state.visibility = newVisibility === 'directed' ? 'directed' : 'public';

        // restore prior buffered context
        state.selectedMessageId =
          state.contextBuffer[newVisibility].selectedMessageId;
        state.familyId = state.contextBuffer[newVisibility].familyId;
        state.openTopicId = state.contextBuffer[newVisibility].openTopicId;
      }
    },

    // re-initialize this slice, excluding the specified keys to preserve
    clear(state) {
      const preserve = ['visibility', 'browerAuto', 'browserFilterPostsOnly'];
      for (const [key, value] of Object.entries(state)) {
        state[key] = preserve.includes(key) ? value : initialState[key];
      }
    },

    // initialize the Inbox Queue
    initInboxQueue(state, action) {
      const newQ = makeArray(action.payload);
      state.inboundIdQueue = newQ;
      state.inboundIdQueueInitialized = true;
      updateBadge(newQ.length).catch(msg => console.log(msg));
    },

    // remove one or more messages from the Inbox Queue
    removeFromInboxQueue(state, action) {
      const removals = action.payload;
      console.log(
        'removeFromInboxQueue:',
        JSON.parse(JSON.stringify(removals))
      );
      dequeue(state, removals);
    },

    //save Dialogs and initialize inbound queue
    save(state, action) {
      const dialogs = action.payload;
      state.dialogs = dialogs;

      state.dialogsFetchedFlg = true;
      //state.selectedMessageId = null //@@ retain prior, if still available
      state.familyId = null;
      state.played = []; //no messages played yet

      // save the new dialogs in both nested and normalized form
      install(state, dialogs);
    },
    // insert a new message into the existing local dialogs list
    insert(state, action) {
      const newMessage = action.payload?.message;
      const userId = action.payload?.userId;
      console.log('insert new message:', newMessage.title);

      // check qualifications
      if (!state.dialogsFetchedFlg) {
        return; //abort, dialogs have not yet been fetched
      }
      if (!Boolean(newMessage && newMessage?.id)) {
        return; // cannot insert a missing message or missing id
      }
      if (state.messageSequence.find(id => id === newMessage.id)) {
        return; // do not insert a duplicate
      }

      const { dialogs, selectedMessageId, familyId } = state;
      let newDialogs;

      /* (unused ) simple placement, new message at top of appropriate list...
            newDialogs = (isTopic(newMessage))
                ? [newMessage, ...state.dialogs]   // place the topic at the top of the dialogs
                : state.dialogs.map(msg =>         // transcribe messages, except update the parent
                    (msg.id === newMessage.parent)
                        //parent found, add to it's response list
                        //(create response list if necessary)
                        ? { ...msg, responses: Array.isArray(msg.responses) ? [newMessage, ...msg.responses] : [newMessage] }
                        //not the parent, keep it unchanged
                        : msg
                )
            */

      // ADD MESSAGE TO dialogs .....

      // new responses are added as members of existing thread
      // new vamil is reorganized into consolidated threads
      // topics (other than vmail) are placed immediately following the currently selected topic
      if (isTopic(newMessage)) {
        if (isThreadedTopic(newMessage.type)) {
          // consolidate messages between correspondents, insert as a new thread
          const newThread = organizeThread(newMessage, state.dialogs);
          const key = directedThreadKey(newThread);
          // place new thread at top of list, remove old thread (if any) from rest of dialogs
          newDialogs = [
            newThread,
            ...dialogs.filter(
              msg =>
                !isThreadedTopic(msg.type) || key !== directedThreadKey(msg)
            ),
          ];
        } else {
          // insert a new topic
          // place immediately following the currently selected topic
          const selectedMessageIx = dialogs.findIndex(
            item => item.id === selectedMessageId
          );
          newDialogs = [
            ...dialogs.slice(0, selectedMessageIx + 1),
            newMessage,
            ...dialogs.slice(selectedMessageIx + 1),
          ];
        }
      } else {
        // insert a response

        // note that parent might be missing, e.g. for regrets to caller
        // (invite may not be loaded into dialogs for the caller)  has not been loaded into dialogs
        //@@ TBD processing: consider dynamically loading the parent and inserting this response

        // update the response list of the parent topic message
        // place newMessage at beginning or embedded in the list depending on conditions
        const parentIx = dialogs.findIndex(
          item => item.id === newMessage.parent
        );
        const parent = dialogs[parentIx];
        if (parent) {
          const responses = Array.isArray(parent?.responses)
            ? parent?.responses
            : [];
          const parentIsSelected = parent?.id === selectedMessageId;
          const familyIsActive = newMessage.parent === familyId;
          const selectedResponseIx = responses.findIndex(
            item => item.id === selectedMessageId
          );
          const placeAtTop =
            !familyIsActive || parentIsSelected || responses.length === 0;
          const newResponses = placeAtTop
            ? [newMessage, ...responses]
            : // place AFTER selected response:
              [
                ...responses.slice(0, selectedResponseIx + 1),
                newMessage,
                ...responses.slice(selectedResponseIx + 1),
              ];
          // transcribe the dialogs, update only the parent
          newDialogs = dialogs.map(msg =>
            msg.id === newMessage.parent
              ? { ...msg, responses: newResponses }
              : msg
          );
        }
      }
      install(state, newDialogs);

      // also enqueue inbound directed messages
      enqueue(state, newMessage, userId);
    },

    // remove a topic message from the dialogs list
    // ( consider expanding to handle responses as well )
    removeTopic(state, action) {
      const id = action.payload;
      console.log('removeTopic:', id);

      // verify that the id is in the dialogs array
      const ixInDialogs = state.dialogs.findIndex(msg => msg.id === id);
      if (ixInDialogs >= 0) {
        const newDialogs = state.dialogs.filter(msg => msg.id !== id);
        install(state, newDialogs);
      }
      dequeue(state, [id]); // also remove it from the inboundIdQueue
    },

    // remove one or more messages (topics and/or responses) from the dialogs list
    // (accepts scaler or array of ids as input)
    //@@ noted: if topic and 1st response are on deathlist
    // entire thread will be zapped
    remove(state, action) {
      const deathList = makeArray(action.payload);
      console.log('remove deathlist:', JSON.parse(JSON.stringify(deathList)));
      // for vmail threads, if deleting root then promote first member
      const promotedMembers = state.dialogs.reduce((out, msg) => {
        const promote =
          msg.type === 'vmail' &&
          deathList.includes(msg.id) &&
          makeArray(msg.responses).length;
        if (promote) {
          const newRoot = {
            //transcribe and reclassify first response
            ...msg.responses[0],
            type: 'vmail',
            parent: null,
            // reassign parent of remaining responses:
            responses: msg.responses
              .slice(1)
              .map(r => ({ ...r, parent: msg.responses[0].id })),
          };
          return [...out, newRoot];
        } else {
          return out;
        }
      }, []);

      // remove deathlisted topics
      // (preappend promoted members before applying deathbed filter)
      const newDialogs = [...promotedMembers, ...state.dialogs].filter(
        msg => !deathList.includes(msg.id)
      );

      //remove deathlisted responses
      newDialogs.forEach(d => {
        d.responses = makeArray(d.responses).filter(
          r => !deathList.includes(r.id)
        );
      });
      install(state, newDialogs);
      dequeue(state, deathList);
    },

    // save the currently selected message identifiers
    setSelectedMessage(state, action) {
      activityDetector.ping('setSelectedMessage');
      const id = action.payload;
      if (id && state.dialogs.length > 0) {
        const oldFamilyId = getFamilyId(
          state.messageMap[state.selectedMessageId]
        );
        const msg = state.messageMap[id];
        if (msg) {
          const newFamilyId = getFamilyId(msg);
          state.selectedMessageId = id;
          state.familyId = newFamilyId;
          // if response is selected, open its parent
          if (isResponse(msg)) {
            state.openTopicId = msg.parent;
          } else {
            //if the family changed, clear any openTopicId
            oldFamilyId === newFamilyId || (state.openTopicId = null);
          }
        }
      } else {
        state.selectedMessageId = null;
        state.familyId = null;
        state.openTopicId = null;
      }
    },

    // set the id to be selected once dialogs are fetched
    setPreloadSelectedMessageId(state, action) {
      state.preloadSelectedMessageId = action.payload;
    },

    // Toggle the 'open' status of the specified Topic message
    // Only one Topic can be open at a time
    // When toggling a Topic, also select it
    toggleOpenTopic(state, action) {
      const id = action.payload;
      const wasOpenId = state.openTopicId;

      // close all topics if id is not a Topic
      if (!Boolean(id) || !isTopic(state.messageMap[id])) {
        state.openTopicId = null;
      } else {
        //toggle
        state.openTopicId = id === wasOpenId ? null : id;

        //regardless of new status, also select it
        state.selectedMessageId = id;
        state.familyId = id;
      }
    },

    // apply flag (bookmark or blacklist) to each message authored by target user
    applyBookmark(state, action) {
      const { targetUser, isPositiveMark } = action.payload;
      const key = isPositiveMark ? 'bookmarked' : 'blacklisted';
      //apply mark to all messages then remove any that are blacklisted
      const dialogs = dialogsExcludeBlacklist(
        state.dialogs.map(post => {
          if (post.userId === targetUser) {
            post[key] = true;
          }
          if (Array.isArray(post.responses)) {
            post.responses = post.responses.map(response => {
              if (response.userId === targetUser) {
                response[key] = true;
              }
              return response;
            });
          }
          return post; //return marked post
        })
      );
      install(state, dialogs);
    },

    // add to a list of messages played this session
    noteMessagePlayedThisSession(state, action) {
      const id = action.payload;
      state.played.push(id);
    },

    // mark a message indicating that it has been played
    // mark messageMap AND dialog topic or dialog response as necessary
    noteDMConsumed(state, action) {
      const id = action.payload;

      const message = state.messageMap[id];
      message && (state.messageMap[id].consumed = true);

      const topic = state.dialogs.find(d => d.id === id);
      if (topic) {
        topic && (topic.consumed = true);
      } else {
        // message was not a topic, mark it within the response list of its parent
        const parent = state.dialogs.find(
          d => d.id === state.messageMap[message.parent].id
        );
        const responses = makeArray(parent?.responses);
        const response = responses.find(r => r.id === id);
        response && (response.consumed = true);
      }
    },

    // remove bookmark (only positive bookmark supported) from all messages
    clearBookmark(state, action) {
      const { targetUser } = action.payload;
      const dialogs = state.dialogs.map(topic => {
        // clear bookmarked flag from all topic messages from targetUser
        topic.userId === targetUser && (topic.bookmarked = false);
        if (Array.isArray(topic.responses)) {
          // clear bookmarked flag from all response messages from targetUser
          topic.responses = topic.responses.map(response => {
            response.userId === targetUser && (response.bookmarked = false);
            return response;
          });
        }
        return topic; //return marked topic
      });
      install(state, dialogs);
    },
  },
});

export const dialogActions = dialogSlice.actions;
export default dialogSlice.reducer;
