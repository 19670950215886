/* Using Paypal SDK button.
 *
 * Notes for SDK button:
 *  - build with "integration builder" https://developer.paypal.com/integration-builder/
 *  - DOCs
 *     - https://developer.paypal.com/sdk/js/
 *     - https://www.paypal.com/merchantapps/setup/checkout/integration
 *     - https://developer.paypal.com/sdk/js/configuration/
 *     - https://developer.paypal.com/sdk/js/reference/
 *     - https://github.com/paypal/react-paypal-js?tab=readme-ov-file
 */

import { useEffect } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { Typography, Grid } from '@material-ui/core';
import useState from 'react-usestateref';

const backEndUrl = 'http://localhost:8888/api/orders';
const clientId =
  'AeuAXhtfgpshKeKcwL55vR0YKhYRltkrsnv13lxzVQbPgGZszc2UcSzuZjUjKvfTlvmTtLE8Dur7sMl1'; //OkSayit_App

export default function PayPalButtonSdk({
  item,
  qty,
  total,
  onError,
  onCapture,
}) {
  const [cart_, setCart, cart] = useState([]);
  const [order_, setOrder, order] = useState(null);

  useEffect(() => {
    setCart([
      {
        sku: item.sku,
        name: item.name,
        quantity: qty,
        total,
      },
    ]);
  }, [item, qty]);

  function createOrder() {
    return fetch(backEndUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      // use the "body" param to optionally pass additional order information
      // like product ids and quantities
      body: JSON.stringify({
        cart: cart.current,
      }),
    })
      .then(response => response.json())
      .then(order => {
        setOrder({ orderID: order.id, status: order.status });
        return order.id;
      })
      .catch(err => {
        console.log('createOrder err:', err);
        onError && onError({ order, error: err });
      });
  }

  function onApprove(approveData) {
    return fetch(`${backEndUrl}/${approveData.orderID}/capture`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orderID: approveData.orderID,
      }),
    })
      .then(response => response.json())
      .then(captureData => {
        const result = {
          ...cart.current[0],
          ...order.current,
          payerID: approveData.payerID,
          paymentSource: approveData.paymentSource,
          status: captureData.status,
          payerEmail: captureData?.payer?.email_address,
          captureTime:
            captureData?.purchase_units[0]?.payments?.captures[0]?.create_time,
        };
        onCapture && onCapture(result);
        return result;
      })
      .catch(err => {
        console.log('onApprove error:', err);
        onError && onError(err);
      });
  }

  return item ? (
    <PayPalScriptProvider
      options={{
        clientId,
        'currency': 'USD',
        'intent': 'capture',
        'enable-funding': 'card',
        'disable-funding': 'paylater,venmo',
      }}
    >
      {/* https://paypal.github.io/react-paypal-js/?path=/docs/example-paypalbuttons--default */}
      <Grid container direction="column">
        <Grid item>
          <PayPalButtons
            style={{
              shape: 'pill',
              layout: 'horizontal',
            }}
            createOrder={createOrder}
            onApprove={onApprove}
            fundingSource={undefined}
          />
        </Grid>
        <Grid item>
          <PayPalButtons
            style={{
              shape: 'pill',
              layout: 'horizontal',
            }}
            createOrder={createOrder}
            onApprove={onApprove}
            fundingSource="card"
          />
        </Grid>
      </Grid>
    </PayPalScriptProvider>
  ) : (
    <Typography variant="h4">ERROR bad item!</Typography>
  );
}
