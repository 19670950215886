/**
 * MembershipLevelsView
 * Describe the available membership plans and features.
 * Offer the opportunity to select a plan and subscribe.
 *
 *
 * Punchlist:
 *  - free trial
 *    - Professional free trial by invitation only
 *    - post transaction to Sale database
 *  - purchase
 *    - "buy now" label clarification (only for visitor, otherwise "upgrade")
 *    - electronic gift codes
 *    - post sale with 'pending' annotation
 *  - MembershipLevelsView
 *    - create route(s) and menu(s) to MembershipLevelsView
 *  - Misc. UI
 *    - dispplay user's current level
 *    - subscription management from within Settings
 *    - warning/notification of subscription expiration
 *  - Paypal
 *    - per-sku Paypal buttons
 *    - capture redirect data, update sale with 'confirmed" annotation
 *
 *
 *  - admin
 *    - implement user archive
 *    - auto synchronize plans.js with back end
 *
 *
 */

import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Divider } from '@material-ui/core';
import { t } from 'lib/translation/trans';
import HorizontalSplit from 'components/layouts/components/HorizontalSplit';
import { catalogItems } from './config/plans.js';
import MembershipPlanMenu from './components/MembershipPlanMenu';
import MembershipPlanConfirmOrder from './components/MembershipPlanConfirmOrder';
import Sale from 'lib/api/Sale';

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1rem',
    },
  },
}));

const MembershipLevelsView = () => {
  const [sku, setSku] = useState(null);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const [request, setRequest] = useState(null);

  const classes = useStyles();

  const handlePlanSelected = plan => {
    console.log('selected:', plan);
    setSelectedPlan(plan);
  };
  const handleSelectedPlanConfirmed = () => {
    console.log('confirmed:', selectedPlan);
    if (selectedPlan?.sample) {
      // complete processing of sample
    } else if (selectedPlan?.sku) {
      // processing of sale
    } else {
      // unexpected condition
    }
  };

  // to be eliminated:
  const handlePlanRequested = request => {
    console.log('request:', request);
    const sale = new Sale({
      sku: request.plan.sku,
      product: `${request.plan.level.key} - ${request.plan.unit}${
        Number(request.plan.sku) < 2000 ? ' (Trial)' : ''
      }`,
      price: request.plan.unitPrice,
      qty: 1,
      gross: request.plan.unitPrice,
      processor: 'test',
      status: 'posted',
      //userId: unknown,
    });

    sale
      .post()
      .then(tx => console.log('post success:', tx.data?.id))
      .catch(e => console.log('post error:', e));

    setRequest(request);
  };

  const planHeading = selectedPlan
    ? `Subscribing to ${selectedPlan.level.title} Plan`
    : '';

  // DEMO to read catalog from HVN server (not used at present)
  /*
  useEffect(() => {
    const catalogUrl = `/jsonapi/node/catalog_service
?fields[node--catalog_service]=title,body,field_level,field_short_name,field_sku,field_unit_price,field_features,&sort=field_sku`;

    // field map: key translation map for fields of interest
    const fmap = {
      title: 'name',
      field_level: 'level',
      field_price: 'price',
      field_sku: 'sku',
    };

    // filter and translate attributes of interest
    const parseServices = data =>
      makeArray(data?.data).map(({ attributes }) =>
        Object.entries(attributes).reduce(
          (acc, [key, val]) => (fmap[key] ? { ...acc, [fmap[key]]: val } : acc),
          {}
        )
      );

    fetcher('jsonapi', 'GET', catalogUrl)
      .then(data => parseServices(data))
      .then(cat => (setCatalog(cat), cat))
      .catch(err => console.log('error fetching service catalog:', err));
  }, []);
*/

  useEffect(() => {
    // get catalog items including samples (trials)
    // do not include automatic (visitor)
    const plans = catalogItems.filter(i => i.level.rank > 0);
    setPlans(plans);
  }, []);

  const handleRequestCancelled = () => {
    setRequest(null);
    setSelectedPlan(null);
  };
  return (
    <HorizontalSplit
      topItem={
        <>
          <Typography classes={{ root: classes.title }} variant="h4">
            {planHeading || 'OkSayit Membership Plans'}
          </Typography>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        </>
      }
      bottomItem={
        selectedPlan ? (
          <MembershipPlanConfirmOrder
            plan={selectedPlan}
            onPlanConfirmed={handleSelectedPlanConfirmed}
            onPlanCancelled={handleRequestCancelled}
          />
        ) : (
          <MembershipPlanMenu
            plans={plans}
            onPlanSelected={handlePlanSelected}
          />
        )
      }
    />
  );
};

export default MembershipLevelsView;
