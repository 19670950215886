import { createSlice } from '@reduxjs/toolkit';
import { tokenStatus } from 'lib/utils/authenticator';
import {
  patchUserInfo,
  applyReciprocalBlacklist,
  getPeer,
  getHvnUser,
  patchUserAwakeStatus,
} from 'lib/api/userApi';
import { nextMsg } from 'lib/api/messageApi';
import { uiActions } from './ui-slice';
import { dialogActions } from './dialog-slice';
import { makeArray } from 'lib/utils/utils';
import { t } from 'lib/translation/trans';
import { activityDetector } from 'lib/classes/ActivityDetector';
import sigServer from 'lib/api/signalingServer';
import { WEB_ROOT } from 'configs/config-hvn';

// userSlice.caseReducers to invoke one reducer from within another

const initialState = {
  initialized: false,
  status: 'anon',
  // 'anon'  anonymous user
  // 'ident' identified user
  // 'auth'  authenticated with HVN server
  // 'asleep' authenticated but inactive
  newAccountCreated: false, // indicates account created this session
  auth: {
    method: null,
    authObject: {},
    socAuthUser: {},
    user: {},
  },
  isAuthenticated: false,
  members: [],
  peers: [],
};
const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    // set current status of user (one of itemized values)
    // (accept value 'awake' as synonym for 'auth' )
    setStatus(state, action) {
      //console.log(`userSlice setStatus =${action.payload}`);
      const newStatus = action.payload.replace('awake', 'auth');
      state.status = ['ident', 'auth', 'asleep'].includes(newStatus)
        ? newStatus
        : 'anon';
    },
    setAuthMethod(state, action) {
      state.auth.method = action.payload;
    },
    login(state, action) {
      const { authObject, socAuthUser } = action.payload;
      if (tokenStatus(authObject) === 'current') {
        state.auth = {
          authObject,
          socAuthUser: socAuthUser || state.auth.socAuthUser, // keep old if new is not` provided
          // mistake??  user: state.auth.userData
        };
        //localStore().setObj("auth", state.auth)
        state.initialized = true;
        state.isAuthenticated = true;
        userSlice.caseReducers.setStatus(state, { payload: 'auth' });
      }
      activityDetector.resume('userSlice login'); // resume monitoring for user activity
    },

    // demo to support WebRTCDemo
    loginDemo(state, action) {
      const { userId, screenName } = action.payload;
      state.isAuthenticated = true;
      state.initialized = true;
      state.auth.user.id = userId;
      state.auth.user.screenName = screenName;
    },

    /*
                // synchronize redux to local store
                noteAuthenticatedUser(state, action) {
                    state.auth = action.payload
                    state.isAuthenticated = true
                    state.initialized = true
                    activityDetector.resume("noteAuthenticatedUser");  // resume monitoring for user activity
                },

        */

    // update user's auth credentials
    refreshAuth(state, action) {
      const auth = action.payload;
      state.auth = auth;
      //localStore().setObj("auth", auth)
      //state.isAuthenticated = true
      //state.initialized = true
    },

    // MISNOMER: this saves the auth object, not the authObject
    // replaces noteAuthenticatedUser and refresh actions
    updateUserAuth(state, action) {
      const updates = action.payload;

      const old =
        state.auth?.authObject?.access_token &&
        JSON.parse(JSON.stringify(state.auth?.authObject?.access_token));
      const new1 = updates?.authObject?.access_token;

      //console.log(`updateUserAuth access_token  ${stringHash(old)} -->> ${stringHash(new1)}.`);
      state.auth = { ...state.auth, ...updates };
      //localStore().setObj("auth", state.auth)
    },

    //update user data in the store, and in local storage
    //overwrite only the keys provided in action.payload
    noteUserData(state, action) {
      const newUser = { ...state.auth.user, ...action.payload };
      state.auth.user = newUser;
      state.isAuthenticated = true;
      userSlice.caseReducers.setStatus(state, { payload: 'auth' });
      state.initialized = true;
      //localStore().setObj("auth", state.auth)
    },
    logout(state) {
      state.auth = initialState.auth;
      state.isAuthenticated = false;
      userSlice.caseReducers.setStatus(state, { payload: 'anon' });
      //localStore().setObj("auth", state.auth)
      state.initialized = true;
      activityDetector.pause('userSlice logout'); // do not monitor events while user is logged out.
      state.newAccountCreated = false;
    },
    setPeers(state, action) {
      const peers = action.payload;
      state.peers = peers;
    },
    setMembers(state, action) {
      const members = action.payload;
      state.members = makeArray(members);
    },
    updateMember(state, action) {
      // update data for a a specified member
      const { userId, update } = action.payload;
      const memberIx = state.members.findIndex(m => m.id === userId);
      if (memberIx > -1 && typeof update === 'object') {
        const member = JSON.parse(JSON.stringify(state.members[memberIx]));
        state.members[memberIx] = { ...member, ...update };
      }
    },
    noteAccountCreated(state) {
      state.newAccountCreated = true;
    },
  },
});

/**
 * action creators using thunk
 */

// retrieve stored credentials if available, refreshing them if necessary
const getStoredCredentialsV2 = getState => {
  const promise = new Promise((resolve, reject) => {
    // retrieve stored credentials
    const pAuth = getState().ui.pSession.pAuth;

    if (pAuth?.user?.id) {
      resolve(pAuth);
    } else {
      // no prior user in local store
      reject('no stored user');
    }
  });
  return promise;
};

// employs persistent authobject from pSession
// relies on fetcher to validate/refresh authObject
export const initializeUser = () => {
  let status = 'anonymous';
  return (dispatch, getState) => {
    getStoredCredentialsV2(getState)
      .then(pAuth => {
        // throw an error if creds are from an unrecoginzed issuing server
        const recognizedIssuer = pAuth.authObject?.issuer === WEB_ROOT;
        if (!recognizedIssuer) {
          throw {
            status: 'credential error',
            data: 'creds unrecognized issueer',
          };
        }
        return pAuth;
      })
      .then(pAuth => {
        // user is identified
        status = 'identified';
        console.log(`initializeUser retrieved stored creds.`);
        dispatch(userActions.setStatus('ident'));
        dispatch(userActions.updateUserAuth(pAuth));
        return getHvnUser({ uuid: pAuth.user.id });
      })
      .then(hvnUser => {
        if (hvnUser.info === undefined) {
          throw {
            status: 'getHvnUser error',
            data: 'failed to read hvnUser.info',
          };
        }
        return hvnUser;
      })
      .then(hvnUser => {
        // user is authenticated
        status = 'authenticated';
        //@@ consider merging/refactoring with loginHvnUser, processUserSleepTransition
        console.log(`initializeUser fetched hvnUser.`);
        dispatch(userActions.setStatus('auth'));
        dispatch(userActions.noteUserData(hvnUser));
        //patchUserAwakeStatus(true);
        sigServer.registerSelfAsPeer('initializeUser');
        activityDetector.resume();
        activityDetector.ping();
        return hvnUser;
      })
      .catch(err => {
        console.log('getStoredCredentialsV2 error:', err);
        if (status == 'anonymous') {
          console.log('Anonymous user.');
          dispatch(userActions.logout());
          dispatch(uiActions.updatePauth({ authObject: {}, user: {} }));
        } else {
          // stored credentials exist, but initialization failed
          console.log('User initialization failed.');
          dispatch(userActions.logout());
          dispatch(uiActions.updatePauth({ authObject: {}, user: {} }));
        }
      });
  };
};

// implement bookmark or blacklist flag
// On the host, bookmarks are stored in the user_info node associated with user record.
// In Redux, they are stored within the 'info' key of the user object
//
// procedure:
// * in redux store
//      * apply the bookmark or blacklist flag
//      * annotate local Dialogs array
// * notify peer
//      * notify blacklisted user (if active) and trigger symmetric blacklist
// * patch host
//      * patch user's info node
//      * apply symmetric patch to blackllisted user info node
//      * record blacklist event annotated with reason
//TODO2:  save blacklistReason
export const setBookmark = ({
  targetUser,
  blacklistReason,
  isPositiveMark,
}) => {
  const log = text => console.log(`setBookmark: ${text}`);
  return (dispatch, getState) => {
    //getPeer will be disabled if blacklist is applied, so get peer info now.
    const targetPeer = getPeer(targetUser);

    // get a mutable copy of user state data
    const user = { info: {}, ...getState().user.auth.user }; //add info key if not present
    const info = { ...user.info }; //get mutable copy

    // apply bookmark
    if (isPositiveMark) {
      //create mutable copy of bookmarks
      const bookmarks = Array.isArray(info.field_bookmarks)
        ? [...info.field_bookmarks]
        : [];
      if (bookmarks.includes(targetUser)) {
        //bookmark is already in place
        log(`already bookmarked.`);
        return null;
      }
      dispatch(uiActions.backdropSet(`${t('Applying_Bookmark')}...`));
      bookmarks.push(targetUser);
      info.field_bookmarks = bookmarks;
    } else {
      //create mutable copy of blacklist
      const blacklist = Array.isArray(info.field_blacklist)
        ? [...info.field_blacklist]
        : [];

      if (blacklist.includes(targetUser)) {
        //already in place
        log(`already blacklisted.`);
        return null;
      }
      dispatch(uiActions.backdropSet(`${t('Updating_Blacklist')}...`));
      blacklist.push(targetUser);
      info.field_blacklist = blacklist;

      // since the currently selected message will be zapped, identify which message
      // will be selected after blacklist is applied.  Do not consider messages from this author.
      const messageMap = getState().dialog.messageMap;
      nextMsg({ auxFilter: id => messageMap[id].userId !== targetUser });
    }
    // update store, localstorage, and host
    user.info = info;
    dispatch(userActions.noteUserData(user)); //update user object in redux store and localstorage
    dispatch(dialogActions.applyBookmark({ targetUser, isPositiveMark })); // also update the existing dialogs array (avoid re-reading it from host)
    patchUserInfo(user.infoId, info) // write the data to the host
      .then(() => {
        dispatch(uiActions.backdropClr());
        if (!isPositiveMark) {
          // apply reciprocal blacklist (blacklist self on targetUser's account)
          applyReciprocalBlacklist(targetUser, targetPeer);
          //@@    .then(() => noteBlacklistReason())
        }
      })
      .catch(err => {
        log('Problem patching user info.', err);
        dispatch(uiActions.backdropClr());
      });
  };
};

// clear positive bookmark
export const clearBookmark = ({ targetUser }) => {
  return (dispatch, getState) => {
    const user = { ...getState().user.auth.user };
    const info = { ...user.info }; //get mutable copy
    const bookmarks = Array.isArray(info.field_bookmarks)
      ? info.field_bookmarks
      : [];
    console.log(`clearBookmark target=${targetUser} : `, bookmarks);
    if (bookmarks.includes(targetUser)) {
      info.field_bookmarks = bookmarks.filter(b => b !== targetUser);
      user.info = info;
      // update user record in redux store and local storage
      dispatch(userActions.noteUserData(user));

      //@@ remove bookmark from dialogs list in redux store
      dispatch(dialogActions.clearBookmark({ targetUser }));

      // update user record in the host
      patchUserInfo(user.infoId, info).catch(err =>
        console.log('Problem patching user info.', err)
      );
    }
  };
};

// apply a reciprocal blacklist mark
// (initiated by remote user)
export const setReciprocalBlacklist = ({ targetUser }) => {
  return (dispatch, getState) => {
    // get a mutable copies
    const user = { info: {}, ...getState().user.auth.user }; //add info key if not present
    const info = { ...user.info }; //get mutable copy
    const blacklist = Array.isArray(info.field_blacklist)
      ? [...info.field_blacklist]
      : [];
    const messageMap = getState().dialog.messageMap;
    if (blacklist.includes(targetUser)) {
      return null; //already on list
    }
    blacklist.push(targetUser);
    info.field_blacklist = blacklist;
    user.info = info;
    dispatch(userActions.noteUserData(user)); //update user object in redux store and localstorage
    nextMsg({ auxFilter: id => messageMap[id].userId !== targetUser });
    dispatch(
      dialogActions.applyBookmark({ targetUser, isPositiveMark: false })
    ); // also update the existing dialogs array (avoid re-reading it from host)
  };
};

// action creators are provided by createSlice
// there is no need to define list text action "types"
export const userActions = userSlice.actions;
export default userSlice.reducer;
