/**
 * webPushApi
 *
 * Utilities for managing server endpoint subscriptions
 *
 */

import store from 'store';
import { uiActions } from 'store/ui-slice';
import { fetcher } from 'lib/utils/fetcher';
import { makeArray } from 'lib/utils/utils';
import { pwa } from 'lib/classes/PWA';

const deleteNode = (type, id) =>
  fetcher('jsonapi', 'DELETE', `jsonapi/node/${type}/${id}`);

const deleteNodes = (type, idList) =>
  Promise.all(idList.map(id => deleteNode(type, id)));

const getUserSubscriptions = () => {
  const user = store.getState().user;
  const fields =
    'fields[push_subscription]=field_user_id,field_channel,field_descriptor';
  const url = `jsonapi/node/push_subscription?${fields}&filter[field_user_id]=${user.auth.user?.id}`;

  return user.isAuthenticated
    ? fetcher('jsonapi', 'GET', url).then(data =>
        data.data.map(item => ({
          id: item.id,
          userId: item.attributes.field_user_id,
        }))
      )
    : Promise.resolve([]);
};

export const deleteUserAllSubscriptions = () =>
  getUserSubscriptions().then(subscriptions =>
    deleteNodes(
      'push_subscription',
      subscriptions.map(item => item.id)
    )
  );

// handle push event from service worker
export const handlePushEvent = data => {
  console.log('HANDLE_PUSH_NOTIFICATION data:', data);
  setTimeout(() => {
    // redundant, also updated by dialog-slice enqueue
    updateBadge().catch(msg => console.log(msg));
  }, 3000);

  //const user = store.getState().user;
  //const asleep = user.status === 'asleep';
};

// permanently record PWA installation event
export const notePWAInstalled = () => {
  store.dispatch(uiActions.notePWAInstalled(true));
};

// update the app icon badge count
export const updateBadge = cntOverride => {
  console.log(`updateBadge cntOverride=${cntOverride}`);
  let cnt = cntOverride;

  if (typeof cntOverride === 'undefined') {
    // getState will throw an error if redux is already busy
    try {
      cnt = makeArray(store.getState().dialog.inboundIdQueue).length;
    } catch (error) {
      console.log('updateBadge getState error:', error);
    }
  }
  console.log(`updateBadge cnt=${cnt}`);

  const setAppBadge = cnt =>
    navigator.setAppBadge
      ? navigator
          .setAppBadge(cnt)
          .then(() => console.log(`setAppBadge ${cnt}`))
          .catch(err => console.log('setAppBadge error:', err))
      : Promise.reject('setAppBadge not supported.');

  const clearAppBadge = () => {
    console.log('clearAppBadge...');
    // remove all notifications from the device's list
    pwa.closeNotifications();

    return navigator.clearAppBadge
      ? navigator.clearAppBadge()
      : Promise.reject('clearAppBadge not supported.');
  };

  return cnt ? setAppBadge(cnt) : clearAppBadge();
};
