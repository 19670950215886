/**
 * MembershipPlanConfirmOrder
 *
 * Register or login the user if necessary,
 * explain the transaction details,
 * and perform either a purchase or sample transaction.
 *
 *
 */

// to get Drupal time:
// https://github.com/GiorgosK/time_service

import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper, IconButton } from '@material-ui/core';
import { catalogItems } from '../config/plans.js';
import { useSelector, useDispatch } from 'react-redux';
import LoginView from 'components/AccountButton/components/LoginView';
import ButtonStd from 'components/utils/ButtonStd';
import PaypalButtonHosted from './PaypalButton/PaypalButtonHosted';
import PaypalButtonConfigurable from './PaypalButton/PaypalButtonConfigurable';
import PaypalButtonSdk from './PaypalButton/PaypalButtonSdk';

import CancelIcon from '@material-ui/icons/Cancel';
import SelectorStd from 'components/utils/SelectorStd';

const buttonType = 'sdk'; // 'sdk', 'hosted', 'configurable'
const getQuantity = buttonType === 'sdk';

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    marginBottom: '2rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1rem',
    },
  },
  container: {
    width: '50%',
    padding: '1rem',
    marginBottom: '2rem',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

// override styles for SelectorStd
const useSelectorStyles = makeStyles(theme => ({
  formControl: {
    margin: 0,
    width: 70,
    marginBottom: '1rem',
  },
}));

const MembershipPlanConfirmOrder = ({
  plan,
  onPlanConfirmed,
  onPlanCancelled,
}) => {
  const [loggingIn, setLoggingIn] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const classes = useStyles();
  const selectorClasses = useSelectorStyles();

  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(state => state.user);

  // if sample is requested, look it up in the catalog
  const purchasePlan = plan;
  const total = Math.floor(quantity * purchasePlan.unitPrice * 100) / 100;

  const samplePlan = plan.sample
    ? catalogItems.find(i => i.level.key === purchasePlan.level.key && i.sample)
    : null;

  // require user to login or register before proceeding
  useEffect(() => {
    isAuthenticated || setLoggingIn(true);
  }, []);

  // one of these should be asserted, if not commence login procedure
  useEffect(() => {
    if (!isAuthenticated && !loggingIn) {
      setLoggingIn(true);
    }
  }, [isAuthenticated, loggingIn]);

  const sku = samplePlan?.sku || purchasePlan?.sku;
  const level = purchasePlan.level.key;

  const handleLoginSuccess = ({ newRegistration }) => {
    setNewUser(newRegistration);
    setLoggingIn(false);
  };

  const handleQtyChange = d => {
    setQuantity(d);
    console.log('handleQtyChange:', d);
  };

  const explain = () => {
    if (level === 'basic') {
      return newUser
        ? 'Congrats new user, you are subscribed in the complimentary "Basic" level membership.'
        : 'Welcome Back, you are currently subscribed in the "Basic" level.';
    } else if (samplePlan) {
      //evaluate eligibility
      return `Enjoy your  ONE-TIME Free Trial`;
    } else {
      // explain purchase experience and price
      return (
        <Grid container justifyContent="center">
          <Grid item container justifyContent="center" alignItems="top">
            {getQuantity && (
              <Grid item>
                <SelectorStd
                  name="quantity"
                  label="Months"
                  items={[
                    { key: '1', data: 1 },
                    { key: '2', data: 2 },
                    { key: '3', data: 3 },
                    { key: '4', data: 4 },
                    { key: '5', data: 5 },
                    { key: '6', data: 6 },
                    { key: '7', data: 7 },
                    { key: '8', data: 8 },
                    { key: '9', data: 9 },
                    { key: '10', data: 10 },
                    { key: '11', data: 11 },
                    { key: '12', data: 12 },
                  ]}
                  value={quantity}
                  onChange={handleQtyChange}
                  classes={selectorClasses}
                />
              </Grid>
            )}

            <Grid
              style={{
                marginLeft: 10,
                marginTop: 5,
              }}
              item
            >
              <Typography>{`$${purchasePlan.unitPrice}  ${purchasePlan.unit}`}</Typography>
            </Grid>
          </Grid>
          <Grid style={{ textAlign: 'center' }} item>
            <Typography variant="body">( NOT a recurring charge )</Typography>
            {getQuantity && (
              <div style={{ marginTop: '1rem' }}>
                <Typography
                  color="secondary"
                  component="span"
                  style={{ fontWeight: 'bold' }}
                >{`Pay Now:   `}</Typography>
                <Typography component="span">{`$ ${total}`}</Typography>
              </div>
            )}
          </Grid>
        </Grid>
      );
    }
  };

  const handleCapture = captureData =>
    console.log('handleCapture:', captureData);
  // render the appropriate form of button
  const button = () => {
    switch (buttonType) {
      case 'sdk':
        return (
          <PaypalButtonSdk
            item={plan}
            qty={quantity}
            total={total}
            onError={() => {}}
            onCapture={handleCapture}
          />
        );
      case 'hosted':
        return <PaypalButtonHosted item={plan} onClick={onPlanConfirmed} />;
      default:
        return (
          <PaypalButtonConfigurable item={plan} onClick={onPlanConfirmed} />
        );
    }
  };

  const actions = () => {
    if (samplePlan) {
      return (
        <ButtonStd
          color="secondary"
          label="Start Now"
          onClick={onPlanConfirmed}
        />
      );
    } else {
      return (
        <>
          <Grid item container justifyContent="center">
            {button()}
          </Grid>
          <div style={{ marginTop: '1rem', textAlign: 'center' }}>
            <Typography component="div" variant="body">
              Powered by Paypal
            </Typography>
          </div>
        </>
      );
    }
  };

  if (loggingIn) {
    return (
      <LoginView onSuccess={handleLoginSuccess} onCancel={onPlanCancelled} />
    );
  } else if (isAuthenticated) {
    return (
      <Grid container justifyContent="center">
        <Paper classes={{ root: classes.container }}>
          <Grid item container justifyContent="center">
            {explain()}
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            style={{ marginTop: '1rem' }}
          >
            {actions()}
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            style={{ marginTop: '1rem' }}
          >
            <IconButton onClick={onPlanCancelled}>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Paper>
      </Grid>
    );
  } else {
    return <h1>ERROR!</h1>;
  }
};
MembershipPlanConfirmOrder.propTypes = {
  plan: PropTypes.object,
  onPlanConfirmed: PropTypes.func,
  onPlanCancelled: PropTypes.func,
};
MembershipPlanConfirmOrder.defaultProps = {
  plan: {},
  onPlanConfirmed: () => {},
  onPlanCancelled: () => {},
};

export default MembershipPlanConfirmOrder;
