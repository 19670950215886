/**
 * MembershipPlanMenu
 *
 * Render descriptions of all available plans.
 *
 */

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Divider,
} from '@material-ui/core';
import { t } from 'lib/translation/trans';
import MembershipLevelActionButtons from './MembershipLevelActionButtons';

const useStyles = makeStyles(theme => ({
  title: { textAlign: 'center' },
  outerContainer: {
    marginTop: '1rem',
  },
  itemContainer: {
    paddingLeft: 5,
    paddingRight: 5,
    marginBottom: '1rem',
  },
  card: {},
  cardContent: {
    paddingBottom: 0,
  },
  centered: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const MembershipPlanMenu = ({ plans, onPlanSelected }) => {
  const classes = useStyles();

  const getSamplePlan = plan => {
    // numeric sku equivalents
    const skuNum = Number(plan.sku);
    const skuNumSample = skuNum - 1000;
    const samplePlan = plans.find(
      p => p.sample && Number(p.sku) === skuNumSample
    );
    return samplePlan;
  };

  return (
    <Grid
      classes={{ root: classes.outerContainer }}
      container
      justifyContent="center"
    >
      {plans
        .filter(p => !p.sample)
        .map(plan => (
          <Grid
            key={plan.sku}
            item
            xs={10}
            sm={6}
            md={4}
            classes={{ root: classes.itemContainer }}
          >
            <Card variant="outlined" classes={{ root: classes.card }}>
              <CardContent classes={{ root: classes.cardContent }}>
                <Typography variant="h6">{plan.level.title}</Typography>
                <Typography
                  color="secondary"
                  variant="subtitle2"
                >{`$${plan.unitPrice} per ${plan.unit}`}</Typography>
                <Divider />
                <CardActions classes={{ root: classes.centered }}>
                  <MembershipLevelActionButtons
                    plan={plan}
                    samplePlan={getSamplePlan(plan)}
                    onClick={onPlanSelected}
                  />
                </CardActions>
                <Divider />
                <ul>
                  {plan.level.features.map(f => (
                    <li key={f}>{f}</li>
                  ))}
                </ul>
                <Divider />
              </CardContent>
            </Card>
          </Grid>
        ))}
    </Grid>
  );
};

export default MembershipPlanMenu;
