/**
 * MembershipLevelsView Configuration
 *
 * Definition of plans, features and prices.
 *
 */

const features = {
  publicPlay: 'Listen to Public Playlists',

  createVoice: 'Create Voice Recordings',
  saveToCloud: 'Save to Cloud',
  voiceSocNet: 'Voice Social Network',
  memberList: 'View Membership List',
  protectId: 'Protected Identity',
  sharePublic: 'Share Public Messages',
  dm: 'Send/Receive Direct Messages',
  inboxBasic: '7-Day Inbox',

  pushNotify: 'Push Notifications',
  filtersPlaylist: 'Playlist Filters',
  bookmarks: 'Bookmark & Blacklist Members',
  liveCall: 'Live Conversations',
  inboxPlus: 'Expanded Inbox',
  skipAds: 'Skip Advertisements',
  cancelAnytime: 'Cancel at Any Time',

  landing: 'Personalized Landing Page',
  branding: 'Branding Tools (Image, Logo, Avatar, etc.)',
  hosting: 'Hosted Discussions',
  eventNotify: 'Event Notifications to Fans/Followers',
  videoList: 'Curated Video/Media List',

  aggregateBasic: '(all Basic features plus... )',
  aggregateSuper: '(all Super features plus... )',
};

const aggregate = {
  super: "(all 'Basic' features plus...)",
  professional: "(all 'Super' features plus...)",
};

export const levels = [
  {
    key: 'visitor',
    title: 'Visitor',
    rank: 0,
    features: ['publicPlay'],
    description: 'Best for first visit by user exploring the OkSayit community',
  },
  {
    key: 'basic',
    title: 'Basic Level (free)',
    rank: 100,
    features: [
      'createVoice',
      'saveToCloud',
      'voiceSocNet',
      'memberList',
      'protectId',
      'sharePublic',
      'dm',
      'inboxBasic',
    ],
    description:
      'Best for new users who wish to use the free version of OkSayit',
  },
  {
    key: 'super',
    title: 'Super Level',
    rank: 200,
    features: [
      'aggregateBasic',
      'pushNotify',
      'filtersPlaylist',
      'bookmarks',
      'liveCall',
      'inboxPlus',
      'skipAds',
      'cancelAnytime',
    ],
    description:
      'Best for frequent users who wish to optimize communication methods and features',
  },
  {
    key: 'professional',
    title: 'Professional Level',
    rank: 300,
    features: [
      'aggregateSuper',
      'landing',
      'branding',
      'hosting',
      'eventNotify',
      'videoList',
    ],
    description:
      'Best for brands, influencers and self-promoters who wish to curate discussions and recruit fans on OkSayit',
  },
];

// sku conventions
// no-charge sku < 1000
// 1000 <= sample.sku < 2000
// 2000 <= paid.sku
// free sample sku is paid.sku-1000

// original representation, consider refactoring to use Map
const catalog = [
  {
    sku: '0000',
    name: 'OkSayit Visitor Level',
    unit: 'month',
    unitPrice: 0,
    level: 'visitor',
    sample: false,
    enable: true,
  },
  {
    sku: '1100',
    name: 'OkSayit Basic Month Subscription',
    unit: 'month',
    unitPrice: 0,
    level: 'basic',
    sample: false,
    enable: true,
  },
  {
    sku: '1200',
    name: 'OkSayit Super Month Trial',
    unit: 'month',
    unitPrice: 0,
    level: 'super',
    sample: true,
    enable: true,
  },
  {
    sku: '1300',
    name: 'OkSayit Professional Month Trial',
    unit: 'month',
    unitPrice: 0,
    level: 'professional',
    sample: true,
    enable: true,
  },
  {
    sku: '2200',
    name: 'OkSayit Super Month Subscription',
    unit: 'month',
    unitPrice: 0.99,
    level: 'super',
    sample: false,
    enable: true,
    btnId: 'JWWMVZNHT2VYG', // used only for PaypalButtonHosted
  },
  {
    sku: '2300',
    name: 'OkSayit Professional Month Subscription',
    unit: 'month',
    unitPrice: 99.0,
    level: 'professional',
    sample: false,
    enable: true,
    btnId: 'KYWRVNZ3RZAVC', // used only for PaypalButtonHosted
  },
];

// alternate representation
const catalog2 = new Map([
  [
    '0000',
    {
      name: 'OkSayit Visitor Level',
      unit: 'month',
      unitPrice: 0,
      level: 'visitor',
      sample: false,
      enable: true,
    },
  ],
  [
    '1100',
    {
      name: 'OkSayit Basic Month Subscription',
      unit: 'month',
      unitPrice: 0,
      level: 'basic',
      sample: false,
      enable: true,
    },
  ],
  [
    '1200',
    {
      name: 'OkSayit Super Month Trial',
      unit: 'month',
      unitPrice: 0,
      level: 'super',
      sample: true,
      enable: true,
    },
  ],
  [
    '1300',
    {
      name: 'OkSayit Professional Month Trial',
      unit: 'month',
      unitPrice: 0,
      level: 'professional',
      sample: true,
      enable: true,
    },
  ],
  [
    '2200',
    {
      name: 'OkSayit Super Month Subscription',
      unit: 'month',
      unitPrice: 0.99,
      level: 'super',
      sample: false,
      enable: true,
      btnId: 'JWWMVZNHT2VYG', // used only for PaypalButtonHosted
    },
  ],
  [
    '2300',
    {
      name: 'OkSayit Professional Month Subscription',
      unit: 'month',
      unitPrice: 99,
      level: 'professional',
      sample: false,
      enable: true,
      btnId: 'KYWRVNZ3RZAVC', // used only for PaypalButtonHosted
    },
  ],
]);

// return all catalog items with level details attached
export const catalogItems = catalog
  .filter(c => c.enable)
  .map(c => {
    // find the level, if it exists
    const level = levels.find(l => l.key === c.level) || {};

    return {
      ...c,
      level: {
        ...level,
        features: level.features.map(f => features[f] || f), // lookup feature names
      },
    };
  });
